@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.App {
  text-align: center;
}
*{
  font-family: 'Poppins', sans-serif;

  font-style: normal;
}
:root {
  --plyr-color-main: #6c04fc;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body{
  background-color: #1F2128;
  
}

h1{
  font-weight: 600;
  font-size: 56px;
  line-height: 64px;
}
#logo-img{
  height: 90px;
  margin: auto;
}
.logo-header{
  display: flex;
  flex-direction: row;
}
#player{
  border-radius: 32px;
  width: 100%;
  z-index: 10;
}

.video-details{
  width: 100%;
  background-color: #242731;
  text-align: left;
  
  padding-top: 4rem;
  margin-top: -3rem;

  border-radius: 32px;
}

.detail-title{
  display: flex;
  flex-direction: column;
  
}

.chat-heading{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chat-heading h6{
  margin-bottom: 2rem;
}
.arrow{
  margin-top: auto;
  margin-bottom: auto;
  transform: rotate(45deg);
}
.chat-body{
  border-radius: 32px;
  border-color: rgba(240, 243, 246, .1);
  border-width: .5px;
  flex-grow: 1;
  width: 100%;
  border-style: solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.App-header {
  background-color: #1F2128;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border-color: rgba(240, 243, 246, .1);
  border-width: .5px;
  border-bottom-style: solid;
}
.chat-body i{
  font-size: 60px;
}
.container{
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  padding-bottom: 3.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1.5rem;
}
h4{
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -0.5px;
}

h6{
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.detail-heading-box{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 3rem;
}

#detail-img{
  height: 130px;
  width: 130px;
  margin: auto;
}
.video-container{
  display: flex;
  flex-direction: column;
  max-width: 60%;
  color: #fff;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.chat-main{
  height: 100%;
  width: 100%;
  background-color: #242731;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  border-color: rgba(240, 243, 246, .1);
  border-width: .5px;
  border-style: solid;
 
}
.chat-pad{
  padding-left: 2rem;
  padding-right: 2rem;
}
.chat-main h6{
  text-align: left;
}
.chat-container{
  display: flex;
  flex-direction: column;
  width: 24%;
  color: #fff;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.alpha-tag div{
  font-weight: 500;
}
.badge-icon{
  margin-right: 1rem;
}
.alpha-tag{
  height: 35px;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #FF754C;
  border-radius: 8px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}
.details-heading{
  padding-right: 4rem;
  margin-top: 1rem;
}



@media only screen and (max-width: 1170px) {
  .container{
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    padding-bottom: 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 1.5rem;
  }

  .video-container{
    display: flex;
    flex-direction: column;
    max-width: 70%;
    color: #fff;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 4rem;
    margin-left: auto;
    margin-right: auto;
  }
  .chat-container{
    display: flex;
    flex-direction: column;
    width: 70%;
    color: #fff;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  .chat-body{
    min-height: 500px;
  }

  h4{
    font-size: 24px;
  }
}

@media only screen and (max-width: 810px){
  #detail-img{
    height: 90px;
    width: 90px;
    margin: auto;
  }

  h1{
    font-size: 32px;
  }
}